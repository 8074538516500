import Image from "next/image";
import { ReactElement } from "react";
import style from "./style.module.scss";

export default function MiddleSection(): ReactElement {
    return (
        <section className=" flex flex-col gap-10 my-[130px]">
            <div className="flex flex-col lg:flex-row justify-between  ">
                <div
                    className={`flex flex-col w-full lg:w-1/2    ${style["mainContent"]} `}
                >
                    <span
                        className={`w-20 h-1 ${style["greenSpan"]} mb-2 mt-20`}
                    />
                    <h2 className="font-bold text-3xl leading-[45px] max-w-lg mb-5">
                        Find Out if You Qualify
                    </h2>
                    <p className="text-lg leading-8 max-w-lg">
                        Recent judicial rulings have allowed individuals who
                        have experienced sexual assault by Uber and Lyft drivers
                        to file lawsuits for their suffering. The California
                        Judicial Council has initiated a Coordinated Proceeding
                        for numerous such cases, enhancing support for those
                        making similar assault or rape claims. This development
                        not only aids victims in their pursuit of justice but
                        also brings wider attention to the issue of rideshare
                        sexual assaults, a concern historically confined to
                        private arbitration.
                    </p>
                </div>
                <div
                    className={`w-[351px] h-[290px] lg:w-[490px]  lg:h-[429px] relative  text-center  ${style["behindImgTop"]} `}
                >
                    <Image
                        src={
                            "/bestridesharelawyers/assets/images/bestsharerideimg1Opt.png"
                        }
                        layout="fill"
                        alt="best ride phone image"
                    />
                </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-between ">
                <div
                    className={`w-[351px] h-[290px] lg:w-[490px]  lg:h-[429px] relative  text-center  ${style["behindImg"]}`}
                >
                    <Image
                        src={
                            "/bestridesharelawyers/assets/images/bestsharerideimg2Opt.png"
                        }
                        layout="fill"
                        alt="best ride car image"
                    />
                </div>
                <div
                    className={`flex flex-col w-full lg:w-1/2   ${style["mainContent"]} `}
                >
                    <span className={`w-20 h-1 ${style["greenSpan"]} mb-2`} />
                    <h2 className="font-bold text-3xl leading-[45px] max-w-lg capitalize mb-5">
                        Can you sue Uber?
                    </h2>
                    <div className="text-lg leading-8 max-w-lg capitalize">
                        <p>
                            Lawyers nationwide are now supporting victims in
                            legal actions against Uber and Lyft for a range of
                            offenses encountered during rides, including:
                        </p>
                        <ul className={style["unorderedList"]}>
                            <li>Sexual harassment</li>
                            <li>Threats</li>
                            <li>False imprisonment (such as locking doors)</li>
                            <li>Sexual assault</li>
                            <li>Attempted rape</li>
                            <li>Rape</li>
                        </ul>
                        <p>
                            If you've been a victim of any of these crimes
                            associated with Uber or Lyft, you may have grounds
                            for a case, and it's advisable to discuss your
                            situation with an attorney promptly.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}
